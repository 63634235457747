<script setup lang="ts">
import type { Role } from "~/stores/api/relationships";

const { name, role, tasksNumber, id } = defineProps<{
  name: string;
  role: Role;
  tasksNumber: number;
  id: string;
}>();

const route = useRoute();
const relationshipsStore = useRelationshipsStore();

const active = computed(() => {
  if (role === "user" && (route.name as string)?.includes("account"))
    return true;

  return id === relationshipsStore.currentWorkspace?.entity_nanoid;
});
</script>

<template>
  <nuxt-link
    :to="
      role === 'user'
        ? '/account/persona/profile'
        : `/${relationshipsStore.roleScopes[role]}/${id}/dashboard`
    "
    class="flex cursor-pointer items-center gap-3 px-4 py-[7.5px] text-sm transition hover:bg-gray-700"
  >
    <div class="flex flex-1 items-center">
      <span>{{ name }}</span>
      <div
        v-if="role && role !== 'user'"
        class="ml-2 flex h-[22px] items-center justify-center rounded-full border border-gray-600 bg-gray-800 px-[10px] text-xs leading-[12px]"
      >
        {{ relationshipsStore.roleMap[role] }}
      </div>
      <div
        v-if="tasksNumber > 0"
        class="flex h-[16px] items-center justify-center rounded-full bg-primary-500 px-[6px] text-[10px] font-bold"
      >
        <span class="-mb-[1px]">{{ tasksNumber }}</span>
      </div>
    </div>
    <font-awesome-icon
      v-if="active"
      icon="check"
      class="px-2 text-sm text-white"
    />
  </nuxt-link>
</template>
