<script setup lang="ts"></script>

<template>
  <img
    src="../../public/metamask.svg"
    alt="Metamask"
    width="32"
    height="32"
    class="mb-4"
  />
  <div class="mb-3 text-2xl font-semibold">Connecting to MetaMask</div>
  <p class="mb-8 text-gray-500">
    Make sure you’re allowing the correct wallet and the correct network.
  </p>
  <div class="space-y-3">
    <div class="space-y-2">
      <div class="text-base font-medium">MetaMask Wallet Address</div>
      <div class="text-gray-500">0xec23...3f0E</div>
    </div>
    <div class="space-y-2">
      <div class="text-base font-medium">Network</div>
      <div class="text-gray-500">Arbitrum</div>
    </div>
  </div>
</template>
