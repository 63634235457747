<script setup lang="ts">
import { ref } from "vue";
import { useWeb3 } from "web3";
import connect from "./connector/connect.vue";
import connecting from "./connector/connecting.vue";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits(["close"]);

const step = ref<"connect" | "connecting" | "processing">("connect");

const web3 = useWeb3();

function close() {
  step.value = "connect";
  emit("close");
}

async function connectWeb3(type: any) {
  step.value = "connecting";
  try {
    await web3.connect(type, props.email);
    close();
  } catch (e) {
    console.error(e);
    step.value = "connect";
  }
}
</script>

<template>
  <div
    class="fixed left-0 z-20 top-0 flex h-screen w-full items-end justify-center p-6"
  >
    <div
      class="absolute left-0 top-0 h-full w-full bg-black/80"
      @click.stop="close"
    />
    <div class="relative max-w-[358px] rounded-2xl bg-white p-6" @click.stop>
      <connect v-if="step === 'connect'" @next="connectWeb3" />
      <connecting v-if="step === 'connecting'" />
    </div>
  </div>
</template>
