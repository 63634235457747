<script setup lang="ts">
const mobileOpen = ref(false);
const animationsEnabled = ref(true);
const route = useRoute();
const relationshipsStore = useRelationshipsStore();
const userStore = useUserStore();

const navigationPath = computed(() => {
  if (!route.name) return;

  const path = route.name.toString().split("-");

  return `${path[0]}-${path[1]}`;
});

watch(
  () => route.path,
  () => {
    mobileOpen.value = false;
  }
);

type NavigationItem = {
  icon: string;
  to: string;
  title: string;
  number?: number;
};

const teamNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-dashboard-3-line",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check-3",
    to: "tasks",
    title: "Action Items",
    number: 5,
  },
  {
    icon: "ri-team-line",
    to: "members",
    title: "Team",
  },
  {
    icon: "ri-secure-payment-line",
    to: "pay",
    title: "Pay",
  },
  // {
  //   icon: "circle-dollar-to-slot",
  //   to: "payroll",
  //   title: "Payroll",
  //   label: "New",
  // },
  {
    icon: "ri-list-view",
    to: "invoices",
    title: "Invoices",
  },
  {
    icon: "ri-account-pin-box-line",
    to: "history/pending",
    title: "Account History",
  },
  {
    icon: "ri-file-4-line",
    to: "documents",
    title: "Documents",
  },
  {
    icon: "ri-bank-card-line",
    to: "deposit/blockchain-address",
    title: "Deposit",
  },
  {
    icon: "ri-equalizer-2-line",
    to: "settings",
    title: "Settings",
  },
]);

const organizationNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-dashboard-3-line",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check-3",
    to: "tasks",
    title: "Action Items",
    number: 5,
  },
  {
    icon: "ri-team-line",
    to: "teams",
    title: "Teams",
    label: "New",
  },
  {
    icon: "ri-account-pin-box-line",
    to: "history/pending",
    title: "Account History",
  },
  {
    icon: "ri-file-4-line",
    to: "treasury/deposit/blockchain-address",
    title: "Documents",
  },
  {
    icon: "ri-account-pin-box-line",
    to: "treasury/deposit/blockchain-address",
    title: "Deposit",
  },
  {
    icon: "ri-equalizer-2-line",
    to: "settings",
    title: "Settings",
  },
]);

const contractorNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-dashboard-3-line",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check",
    to: "tasks",
    title: "Action Items",
    number: 5,
  },
  {
    icon: "ri-hand-coin-line",
    to: "withdraw",
    title: "Withdraw",
    label: "New",
  },
  {
    icon: "ri-list-view",
    to: "invoices",
    title: "Invoices",
  },
  {
    icon: "ri-table-view",
    to: "history/pending",
    title: "Account History",
  },
  {
    icon: "ri-file-line",
    to: "documents",
    title: "Documents",
  },
]);

const employeeNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-gauge",
    to: "dashboard",
    title: "Dashboard",
  },
  {
    icon: "ri-list-check",
    to: "tasks",
    title: "Action Items",
    number: 5,
  },
  {
    icon: "ri-hand-holding-dollar",
    to: "withdraw",
    title: "Withdraw",
    label: "New",
  },
  {
    icon: "ri-receipt",
    to: "reimbursements",
    title: "Reimbursements",
  },
  {
    icon: "ri-scroll",
    to: "pay-slips",
    title: "Pay Slips",
  },
  {
    icon: "ri-star",
    to: "benefits",
    title: "Benefits",
  },
  {
    icon: "ri-file-signature",
    to: "documents",
    title: "Documents",
  },
]);

const accountNavigationItems: NavigationItem[] = reactive([
  {
    icon: "ri-circle-user",
    to: "persona/profile",
    title: "Professional Persona",
  },
  {
    icon: "ri-building",
    to: "organizations",
    title: "Organizations",
  },
  {
    icon: "ri-key",
    to: "passkey",
    title: "Passkey",
  },
  {
    icon: "ri-shield-halved",
    to: "security",
    title: "Security",
  },
  // {
  //   icon: "bolt",
  //   to: "perks",
  //   title: "Perks",
  // },
  {
    icon: "ri-code",
    to: "developer",
    title: "Developer",
  },
  {
    icon: "ri-star",
    to: "referral",
    title: "Referral",
  },
]);

const navigationItems = computed(() => {
  if (relationshipsStore.currentScope === "contractors") {
    return contractorNavigationItems;
  }

  if (relationshipsStore.currentScope === "teams") {
    return teamNavigationItems;
  }

  if (relationshipsStore.currentScope === "employees") {
    return employeeNavigationItems;
  }

  if (relationshipsStore.currentScope === "organizations") {
    return organizationNavigationItems;
  }

  return accountNavigationItems.filter((e) => {
    if (e.to === "developer" && userStore.isPayee) return false;

    return true;
  });
});
</script>

<template>
  <div
    id="left_menu"
    class="fixed left-0 top-0 z-20 flex h-full max-h-screen flex-col border-r border-gray-800 bg-black print:!hidden"
    :class="{ '!h-20 md:!h-full md:overflow-visible': !mobileOpen }"
  >
    <div
      v-if="animationsEnabled"
      class="pointer-events-none !visible absolute left-0 top-0 h-full w-full overflow-hidden"
      :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
    >
      <div class="absolute h-[100vh] w-[500px]">
        <video class="object-fill h-full" autoplay loop muted>
          <source src="/videos/menu-bg.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <div
      class="flex-0 z-10 flex h-20 items-center justify-between p-5 md:h-fit md:px-5 md:py-[25.5px]"
      :class="{ 'border-b border-gray-800 md:border-none': mobileOpen }"
    >
      <div
        class="!visible flex h-14 w-14 cursor-pointer items-center justify-center text-white md:hidden"
        @click="mobileOpen = !mobileOpen"
      >
        <font-awesome-icon v-show="!mobileOpen" icon="bars" class="!h-6 !w-6" />
        <font-awesome-icon v-show="mobileOpen" icon="times" class="!h-6 !w-6" />
      </div>
      <nuxt-link to="/">
        <img
          class="w-[90px] w-auto md:w-[90px]"
          src="/brand/rise-logo-white.svg"
          alt="Rise Works Inc."
        />
      </nuxt-link>
      <RiseDarkModeToggle v-model="userStore.theme" />
    </div>
    <div
      v-if="navigationPath"
      class="!visible relative z-10 min-h-0 flex-1 overflow-x-auto px-3 pt-2 md:pt-0"
      :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
    >
      <LeftMenuItem
        v-for="item in navigationItems"
        :key="item.title"
        :icon="item.icon"
        :title="item.title"
        :number="item.number"
        :to="
          relationshipsStore.currentScope === 'unknown'
            ? `/account/${item.to}`
            : `/${relationshipsStore.currentScope}/${route.params.workspaceId}/${item.to}`
        "
      />
    </div>
    <div
      class="z-10 px-3"
      :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
    >
      <LeftMenuCard />
      <div class="py-6">
        <LeftMenuItem
          icon="ri-questionnaire-line"
          title="Support"
          to="https://help.riseworks.io/en/"
          class="mb-1"
          target="_blank"
          variant="secondary"
        />
        <LeftMenuItem
          icon="ri-logout-circle-r-line"
          title="Sign out"
          to="/sign-out"
          variant="secondary"
        />
      </div>
    </div>
  </div>
</template>
