<script setup lang="ts">
const relationshipsStore = useRelationshipsStore();
</script>

<template>
  <div class="rounded-lg px-3 py-4 bg-gradient-20">
    <VDropdown
      v-if="relationshipsStore.workspaces"
      :distance="6"
      placement="right-end"
    >
      <div
        class="relative mb-4 flex cursor-pointer items-center gap-2 rounded-lg bg-white/10 px-3 py-[7.5px] text-white backdrop-blur-[2px] transition hover:bg-white/20"
        @click="relationshipsStore.relationshipsIncreased = false"
      >
        <div
          class="absolute left-0 top-0 h-full w-full rounded-lg border border-white/20"
        ></div>
        <div class="flex-1">
          <div
            class="mb-1 flex items-center gap-1 text-[10px] font-bold uppercase text-gray-300/70"
          >
            <span>{{
              relationshipsStore.currentWorkspace
                ? relationshipsStore.currentWorkspace.name
                : "Rise Platform"
            }}</span>
            <font-awesome-icon icon="info-circle" />
          </div>
          <div class="text-sm font-medium leading-[14px]">
            {{
              relationshipsStore.currentWorkspace
                ? relationshipsStore.roleMap[
                    relationshipsStore.currentWorkspace.type
                  ]
                : "Account"
            }}
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="bg-gradient-3 relative flex h-[20px] items-center justify-center rounded-full px-2 py-[2px] text-xs font-bold"
          >
            <div
              v-if="relationshipsStore.relationshipsIncreased"
              class="bg-gradient-3 absolute left-0 top-0 h-full w-full animate-ping rounded-full"
            ></div>
            <span class="relative">{{
              relationshipsStore.workspaces.length
            }}</span>
          </div>
          <font-awesome-icon icon="chevron-right" class="px-[5.5px] text-xs" />
        </div>
      </div>

      <!-- This will be the content of the popover -->
      <template #popper>
        <div
          class="min-w-[280px] rounded-lg bg-gray-800 font-medium text-white overflow-hidden py-3"
        >
          <div
            v-for="workspace in relationshipsStore.workspaces"
            :key="workspace.entity_nanoid"
            class="mb-2"
          >
            <div class="mb-1 px-4 text-sm font-normal text-gray-400">
              Organization: {{ workspace.name }}
            </div>
            <LeftMenuCardWorkspaceItem
              v-if="
                workspace.type &&
                ['organizations', 'contractors'].includes(
                  relationshipsStore.roleScopes[workspace.type]
                )
              "
              :id="workspace.entity_nanoid"
              :name="workspace.name"
              :role="workspace.type"
              :tasks-number="0"
            />
            <LeftMenuCardWorkspaceItem
              v-for="teamWorkspace in workspace.teams"
              :id="teamWorkspace.entity_nanoid"
              :key="teamWorkspace.entity_nanoid"
              :name="teamWorkspace.name"
              :role="teamWorkspace.type"
              :tasks-number="0"
            />
          </div>
          <div
            class="mb-1 mt-2 border-t border-gray-700 px-4 pt-3 text-sm font-normal text-gray-400"
          >
            Rise Platform
          </div>
          <LeftMenuCardWorkspaceItem
            id="0"
            name="Account"
            role="user"
            :tasks-number="0"
          />
        </div>
      </template>
    </VDropdown>

    <div>
      <div class="text-[10px] font-bold uppercase text-white opacity-50">
        {{
          relationshipsStore.currentScope === "unknown"
            ? "Account"
            : relationshipsStore.scopeName(relationshipsStore.currentScope)
        }}
        Balance
      </div>
      <div class="font-mono text-[30px] font-bold leading-[38px] text-white">
        {{ $filters.currency(5000) }}
      </div>
    </div>
    <div
      v-if="relationshipsStore.currentWorkspace?.type === 'company'"
      class="mt-2"
    >
      <div class="text-[10px] font-bold uppercase text-white opacity-50">
        Withdraw Fee Pool Balance
      </div>
      <div class="font-mono text-[22px] font-bold text-white">
        {{ $filters.currency(120) }}
      </div>
    </div>
  </div>
</template>
