<script setup lang="ts">
import { ref } from "vue";

const showDetails = ref(false);
</script>

<template>
  <div
    class="fixed left-0 z-20 top-0 flex h-screen w-full items-end justify-center p-6"
  >
    <div class="absolute left-0 top-0 h-full w-full bg-black/80" />
    <div class="relative max-w-[358px] rounded-2xl bg-white p-6" @click.stop>
      <font-awesome-icon icon="microchip" class="mb-4 text-[32px]" />
      <div class="mb-3 text-2xl font-semibold">Processing</div>
      <p class="mb-8 text-gray-500">
        Your transaction is being submitted to a blockchain, the underpinnings
        of Rise which makes us more secure than other payment platforms.
      </p>
      <RiseButton
        v-if="!showDetails"
        size="base"
        class="w-full flex-1"
        variant="outline"
        text="Show Details"
        @click="showDetails = true"
      />
      <div v-if="showDetails" class="space-y-3">
        <div class="space-y-2">
          <div class="text-base font-medium">To: RiseIDBusiness</div>
          <div class="text-gray-500">0xec23...3f0E</div>
        </div>
        <div class="space-y-2">
          <div class="text-base font-medium">From: Owner</div>
          <div class="text-gray-500">0xec23...3f0E</div>
        </div>
        <div class="space-y-2">
          <div class="text-base font-medium">
            Method: ExcuteRise(bytes_data) returns (bytes result)
          </div>
          <div class="text-gray-500">0xec23...3f0E</div>
        </div>
        <div class="space-y-2">
          <div class="text-base font-medium">MethodSignature</div>
          <div class="text-gray-500">0xec23...3f0E</div>
        </div>
      </div>
    </div>
  </div>
</template>
